import mixpanel from 'mixpanel-browser';

let initialized = false;

export function initTracking() {
  if (initialized) return;
  if (!process.env.MIXPANEL_TOKEN) return;

  mixpanel.init(process.env.MIXPANEL_TOKEN, {
    api_host: `https://${window.location.hostname}/track`,
    debug: process.env.NODE_ENV === 'development',
    autotrack: true,
    // track_pageview: true,
    persistence: 'localStorage',
  });
  mixpanel.identify();
  initialized = true;

  document.addEventListener('click', (event) => {
    if (!(event.target instanceof Element)) return;

    const elem = event.target.closest('[data-mp-id]');
    if (!elem) return;

    const mpId = elem.getAttribute('data-mp-id');
    if (!mpId) return;

    if (mpId)
      mixpanel.track(
        mpId,
        elem.tagName.toLowerCase() === 'a'
          ? {
              transport: 'sendBeacon',
              send_immediately: true,
            }
          : undefined
      );
  });
}

export function track(...args: Parameters<typeof mixpanel.track>) {
  if (!initialized) return;
  mixpanel.track(...args);
}
