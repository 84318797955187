import {
  CookieSettings,
  getCookieSettings,
  setCookieSettings,
} from '@/lib/cookies';
import infoIcon from '@/assets/icons/info.svg';

const html = `
  <div class="taskode-cookies-modal" id="cookies-modal">
      <div class="taskode-cookies-main" id="cookies-main-block">
        <img
          src="${infoIcon}"
          class="taskode-cookies-icon"
          alt="Taskode"
        />

        <div class="taskode-cookies-text">
          We use cookies to personalize content, to be able to offer functions
          for social media and to analyze access to our website. We also share
          information about your use of our website with our social media,
          advertising and analytics partners. Our partners may combine this
          information with other data that you have provided to them or that
          they have collected as part of your use of the services.
        </div>

        <div class="taskode-cookies-buttons">
          <button
            class="taskode-button taskode-button-primary"
            id="cookies-consent"
          >
            Consent
          </button>
          <button class="taskode-button" id="cookies-customize">
            Customize
          </button>
          <button class="taskode-button" id="cookies-reject">Reject</button>
        </div>
      </div>

      <div class="taskode-cookies-customize" id="cookies-customize-block">
        <div class="taskode-cookies-customize-checkboxes">
          <label>
            <input type="checkbox" checked disabled />
            <span>
              <b>Essential</b>. Cookies used to let you access your personal
              pages.
            </span>
          </label>

          <label>
            <input type="checkbox" checked id="cookies-analytics-cb" />
            <span>
              <b>Analytics</b>. Helps us to improve user experience and
              performance.
            </span>
          </label>
        </div>

        <div class="taskode-cookies-buttons">
          <button
            class="taskode-button taskode-button-primary"
            id="cookies-customize-save"
          >
            Save
          </button>
          <button class="taskode-button" id="cookies-customize-cancel">
            Cancel
          </button>
        </div>
      </div>
    </div>
`;

export function checkCookieSettings(
  callback: (cookieSettings: CookieSettings) => void
) {
  const savedSettings = getCookieSettings();
  if (savedSettings) {
    callback(savedSettings);
    return;
  }

  document.body.insertAdjacentHTML('beforeend', html);

  const modal = document.getElementById('cookies-modal');
  const mainBlock = document.getElementById('cookies-main-block');
  const customizeBlock = document.getElementById('cookies-customize-block');
  const consentButton = document.getElementById('cookies-consent');
  const customizeButton = document.getElementById('cookies-customize');
  const rejectButton = document.getElementById('cookies-reject');
  const cAnalyticsCb = document.getElementById('cookies-analytics-cb');
  const cSaveButton = document.getElementById('cookies-customize-save');
  const cCancelButton = document.getElementById('cookies-customize-cancel');

  if (
    !modal ||
    !mainBlock ||
    !consentButton ||
    !customizeButton ||
    !rejectButton ||
    !customizeBlock ||
    !cAnalyticsCb ||
    !cSaveButton ||
    !cCancelButton
  )
    return;

  const cookieSettings: CookieSettings = { analytics: true };

  const save = () => {
    setCookieSettings(cookieSettings);
    modal.remove();
    callback(cookieSettings);
  };

  consentButton.addEventListener('click', save);

  customizeButton.addEventListener('click', () => {
    mainBlock.style.display = 'none';
    customizeBlock.style.display = 'flex';
  });

  rejectButton.addEventListener('click', () => {
    modal.remove();
  });

  cAnalyticsCb.addEventListener('click', (e: MouseEvent) => {
    cookieSettings.analytics = (e.target as HTMLInputElement).checked;
  });

  cSaveButton.addEventListener('click', save);

  cCancelButton.addEventListener('click', () => {
    mainBlock.style.display = 'flex';
    customizeBlock.style.display = 'none';
  });
}
