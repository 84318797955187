import { COOKIE_SETTINGS_KEY } from '@/constants/cookies';

export type CookieSettings = {
  analytics: boolean;
};

export function getCookieSettings(): CookieSettings | null {
  try {
    const str = localStorage.getItem(COOKIE_SETTINGS_KEY);
    return str ? JSON.parse(str) : null;
  } catch (e) {
    return null;
  }
}

export function setCookieSettings(cookieSettings: CookieSettings) {
  localStorage.setItem(COOKIE_SETTINGS_KEY, JSON.stringify(cookieSettings));
}
