import { CookieSettings } from '@/lib/cookies';
import { initTracking } from '@/lib/track';
import { checkCookieSettings } from './cookies';

window.addEventListener('DOMContentLoaded', () => {
  if (document.cookie.match(/accessToken=/)) {
    const loginLink = document.getElementById('login-link');
    const dashboardLink = document.getElementById('dashboard-link');

    if (loginLink && dashboardLink) {
      loginLink.style.display = 'none';
      dashboardLink.style.display = 'block';
    }
  }

  const now = new Date();
  const copyrightYear = document.getElementById('copyright-year');

  if (copyrightYear) copyrightYear.innerText = now.getFullYear().toString();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  checkCookieSettings((cookieSettings: CookieSettings) => {
    if (!cookieSettings?.analytics) return;

    initTracking();
  });
});
